body {
  background: #5383A4;
  background: -moz-radial-gradient(center, #5383A4 0%, #304B5B 100%);
  background: -webkit-radial-gradient(center, #5383A4 0%, #304B5B 100%);
  background: radial-gradient(ellipse at center, #5383A4 0%, #304B5B 100%);
}

.MuiBottomNavigation-root {
  background-color: transparent !important;
}
#devryLogo {
  width: 140px;
}
#jeremyLogo {
  width: 200px;
}
.highlight {
  color: #a4a4a4;
}

.accordian:hover {
  transition: all .1s ease-in-out;
  transform: scale(1.03);
  text-shadow: 1px 1px 12px black;
}
.foot:hover {
  transition: all .1s ease-in-out;
  transform: scale(1.03);
  text-shadow: 1px 1px 12px black;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px)  {

  #devryLogo {
    width: 90px;
    height: 95px;
  }
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px)  {

  #jeremyLogo {
    width: 150px;
    height: 95px;
  }
}

.headerLink:hover {
color: rgb(54, 52, 52);
transition: transform 0.5s ease-out;
transform: translateY(6px);
z-index: 95;
}

.contactLink:hover {
  transition: all .1s ease-in-out;
  transform: scale(1.2);
  }

.back:hover {
  box-shadow: 0px 1.5px 9px #292727;
  padding-left: .9rem;
  display: flex;
  gap: 3px;
  }

.btn:hover {
  /* transition: all .1s ease-in-out;
  transform: scale(1.02);
  box-shadow: 9px 8px 4px black; */
  }
  
.highlightBlue {
  color: #5383A4;
  text-shadow: 1px 1px 1px black;
}
 
#root > div > div > div > div.MuiBottomNavigation-root.css-16lloyr-MuiBottomNavigation-root {

  background-color: rgba(0, 0, 0, 0);
}
#root > div > div > div > div.MuiBottomNavigation-root.css-l8ag82-MuiBottomNavigation-root {
  background-color: rgba(0, 0, 0, 0);
}

#panel1bh-header {
  background-color: #494949;
  text-shadow: 1.5px 1.5px 4px #292727
}